import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ErrorContent } from './StyledElements';
import SmallCheckmarkIcon from '../assets/icons/SmallCheckmark.svg';

const checkboxSize = 26;

const Checkbox = styled.input`
  display: none;
  opacity: 0;
  position: absolute;

  & + div {
    cursor: pointer;
    position: relative;
    margin-left: ${checkboxSize + 20}px;
    color: #27b0d4;
    text-decoration: none;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -${checkboxSize + 20}px;
      height: ${checkboxSize}px;
      width: ${checkboxSize}px;
      border: 2px solid #27b0d4;
      border-radius: 2px;
    }
  }

  &:checked + div::before {
    background-image: url(${SmallCheckmarkIcon});
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
  }
`;

const CheckboxLabel = styled.label`
  &.centered {
    display: flex;
    justify-content: center;
  }
`;

const CheckboxContainer = styled.div`
  &.centered {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

const CheckboxField = (props) => {
  return (
    <CheckboxContainer className={props.centerErrorMessage ? 'centered' : ''}>
      <CheckboxLabel className={props.labelClassName}>
        <Checkbox
          id={props.id}
          type="checkbox"
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
        />
        <div className="checkbox-content">{props.children}</div>
      </CheckboxLabel>
      {props.error && <ErrorContent>{props.error}</ErrorContent>}
    </CheckboxContainer>
  );
};

CheckboxField.defaultProps = {
  checked: false
};

CheckboxField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  error: PropTypes.string,
  id: PropTypes.string,
  labelClassName: PropTypes.string,
  centerErrorMessage: PropTypes.bool,
};

export default CheckboxField;
