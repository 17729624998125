import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withWizard } from 'react-albus';
import { TextBlock, Spacing, Button, NarrowWrapper, SectionSeparator } from '../../components/StyledElements';
import PrettyFormSection from '../../components/PrettyFormSection';
import PromoCodeForm from '../../components/PromoCodeForm';
import Teaser from '../../components/Teaser';
import { LicensePriceTeaser } from '../../components/License/LicenseTeaser';
import BaseLicenseDescription from '../../components/License/LicenseDescription';
import NewsletterCheckbox from '../../components/Newsletter/NewsletterCheckbox';
import OrderTotal from './OrderTotal';
import media from '../../utils/media';
import ErrorMessage from '../../components/ErrorMessage';
import TosLink from '../../components/TosLink';
import CheckboxField from 'components/CheckboxField';

const LicenseDescription = styled(BaseLicenseDescription)`
  ${media.md`
    li {
      display: inline-block;
      width: 50%;
      padding-right: 0.5em;
    }
  `};
`;

const CheckOrderDataStep = (props) => {
  const goToPreviousStep = () => {
    props.previous(props.wizard);
  };

  const [tosConsent, setTosConsent] = useState(false);
  const [tosConsentError, setTosConsentError] = useState(false);

  const handleChange = useCallback(() => {
    setTosConsentError(tosConsent);
    setTosConsent((t) => !t);
  }, [setTosConsent, tosConsent]);

  const { organizationAddress = {}, orderAddress = {}, billingAddress = {} } = props.formValues;
  const { license } = props;
  const isMultiUserOrganization =
    organizationAddress.type && organizationAddress.type !== 'SINGLE_USER' && license.maxUsers > 1;

  // Select the country to choose the correct tax rate
  let { country } = isMultiUserOrganization ? organizationAddress : orderAddress;
  // Use the billing address whenever possible
  if (billingAddress.country) {
    country = billingAddress.country;
  }

  return (
    <div>
      <LicensePriceTeaser license={license} />
      <NarrowWrapper>
        <LicenseDescription description={license.description} />
        <SectionSeparator className="is-centered" mt={1} mb={3} />
        <OrderTotal price={license.netPrice} country={country} />
        <Spacing mt={2.5} mb={6}>
          <PromoCodeForm />
        </Spacing>
        {!isMultiUserOrganization && (
          <Fragment>
            <PrettyFormSection headlineValue="Bestelladresse" values={orderAddress} onButtonClick={goToPreviousStep} />
            <SectionSeparator mt={3} mb={3} />
          </Fragment>
        )}
        <PrettyFormSection
          headlineValue="Rechnungsadresse"
          values={billingAddress}
          defaultText="Identisch mit der Bestelladresse."
          onButtonClick={goToPreviousStep}
        />
        {isMultiUserOrganization && (
          <Fragment>
            <SectionSeparator mt={3} mb={3} />
            <PrettyFormSection
              headlineValue="Institution"
              values={organizationAddress}
              defaultText="Keine Institution angegeben."
              onButtonClick={goToPreviousStep}
            />
          </Fragment>
        )}
        <SectionSeparator mt={3} mb={3} />
        <NewsletterCheckbox checked={props.newsletterConsent} onChange={props.onNewsletterConsentChange} />
      </NarrowWrapper>
      <TextBlock lineHeight={1.5} centered fontSize={1.25} mt={3.5}>
        Nach Klick auf den Button "Lizenz kostenpflichtig kaufen" erhalten Sie eine Bestellbestätigung und eine Rechnung an die
        angegebene E-Mail-Adresse. Nach Zahlungseingang wird Ihre Lizenz innerhalb von 2-5 Werktagen freigeschaltet
        (Vertragsschluss).
      </TextBlock>
      <CheckboxField
        name="tosConsentOrder"
        checked={tosConsent}
        centerErrorMessage={true}
        error={tosConsentError ? 'Bitte stimmen Sie den AGB zu.' : ''}
        onChange={handleChange}
        id="tosConsentOrder"
      >
        <TextBlock bold italic centered lineHeight={1.5} mt={3}>
          Hiermit stimme ich den <TosLink>AGB</TosLink> zu.
        </TextBlock>
      </CheckboxField>
      <Spacing textAlign="center" mt={3}>
        <Button
          disabled={!tosConsent}
          onClick={() => {
            if (!tosConsent) {
              setTosConsentError(true);
            } else {
              props.onSubmit();
            }
          }}
        >
          Lizenz kostenpflichtig kaufen
        </Button>
      </Spacing>
      <ErrorMessage error={props.errorMessage} />
      <Spacing mb={6} />
      <Teaser title="Haben Sie Fragen?">
        <TextBlock centered fontSize={1.5}>
          Onilo Service-Hotline:
        </TextBlock>
        <TextBlock centered mb={2} fontSize={2.25}>
          040 / 468 962 390
        </TextBlock>
        <TextBlock centered color={'#89d5e8'} lineHeight={1.6}>
          Anruf aus Deutschland zum nationalen Festnetztarif. Anruf aus Österreich für 22 Cent pro Minute aus dem Festnetz der
          Telekom Austria. Anruf aus der Schweiz für 12 Rappen pro Minute aus dem schweizer Festnetz. Bei Anrufen aus
          Mobilfunknetzen können höhere Kosten entstehen.
        </TextBlock>
      </Teaser>
    </div>
  );
};

CheckOrderDataStep.propTypes = {
  license: PropTypes.object,
  formValues: PropTypes.object,
  previous: PropTypes.func,
  onSubmit: PropTypes.func,
  newsletterConsent: PropTypes.bool,
  onNewsletterConsentChange: PropTypes.func,
  errorMessage: PropTypes.string,
  wizard: PropTypes.object,
};

export default withWizard(CheckOrderDataStep);
